/* .popupCallBox {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  top: 10px;
  right: 5px;
  position: fixed;
  width: 450px;
  height: 85px;
  padding: 5px;
  background: antiquewhite;
  border: 1px solid yellow;
} */



.btnclose {
  width: 20px;
  border: none;
  position: absolute;
  right: 0px;
  text-align: right;
  color: red;
  top: -7px;
  /* padding: 00px 0px 2px 2px; */
  cursor: pointer;
  font-size: large;
}
.site-card-border-less-wrapper {
  padding: 30px;
  background: #ececec;
}
.popupCallBox {
  top: 125px;
  right: 321px;
  position: fixed;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: antiquewhite;
  border: 1px solid yellow;
  padding: 0px;
  border-radius: 5px;
}

.moveable .ant-card-head {
  cursor: move;
}
.moveable .ant-card-head:hover {
  background: antiquewhite;
}

.numpad {
  position: absolute;
  right: -321px;
  width:315px;
  background: bisque;
  padding: 17px;
  
  top: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.numpad_header {
  text-align: center;
  padding: 5px;
  font-weight: 700;
  color: coral;
  margin-top: -18px;
  margin-bottom: 10px;
  text-decoration-line: underline;
}

.iconPhoneHangup {
  color: red;
}
.btnPhoneHangup {
  color: blue;
}
.numpad .ant-col {
  margin: 5px;
}

.transferto {
  position: absolute;
  left: -320px;
  bottom: 0px;
  background: aqua;
  padding: 10px;
}
.AnswerGroup {
  display: flex;
}

.TiketNote {
  z-index: 20;
  background: var(--BG1);
  color: var(--Color1);
  position: fixed;
  bottom: 5px;
  left: 25%;
  width: 52%;
  height: 85%;
  border: 2.5px solid var(--headerBG);
  /* padding: 10px; */
  resize: both;
  overflow: auto;
  /* box-shadow: 0px 30px 9px -20px #ccc; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.TiketNote .ant-tabs-nav {
  padding-left: 10px;
}
.form-field {
  position: relative;
  margin: 0 5px;
  flex: 1;
}
.form-textareaVietAI {
  width: 100% !important;
  /* min-height: 350px; */
  background-image: -webkit-linear-gradient(left, #fff, transparent 0),
    -webkit-linear-gradient(right, #fff, transparent 0),
    -webkit-linear-gradient(#fff 30px, #ccc 0, #ccc 0, #fff 31px);
  background-size: 100% 100%, 100% 100%, 100% 31px;
  border-radius: 8px;
  line-height: 31px;
  font-family: Helvetica, OpenSans, sans-serif;
  border: none;
  outline: none;
  resize: none;
  font-size: 16px;
  font-weight: 500;
  color: #47494b;
  text-align: justify;
}
.form-textarea {
  resize: none;
}
.form-field input {
  font-size: 15px;
  height: 25px;
  /* font: 400 17px; */
  line-height: 24px;
}
/* .form-field textarea {
  height: 20px;
} */
.form-input {
  padding: 0 5px;
  border: none;
  border-bottom: 1px solid var(--blue1);
  border-radius: 4px;
  width: 100%;
  /* height: 50px; */
  transition: 0.25s ease;
  color: #1a201cd4;
}
.form-select {
  /* padding: 0 5px;
  border: none; */
  border-bottom: none;
  /* border-radius: 4px;
  width: 100%;
  /* height: 50px; */
  /* transition: 0.25s ease;
  color: #1a201cd4;  */
}
.form-input:focus {
  border: none;
  border-bottom: 1px solid var(--blue1);
  outline: none;
  background-color: aliceblue;
}
.form-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 6px;
  user-select: none;
  color: #999;
  pointer-events: none;
  transition: 0.25s ease;
}

.form-input:not(:placeholder-shown) + .form-label,
.form-input:focus + .form-label {
  color: var(--blue1);
  font-weight: 500;
  top: -10px;
  left: 0px;
  /* background-color: white; */
  /* padding: 0 10px; */
  display: inline-block;
}

.TiketNote h3 {  
  border: none;
}


.TiketNote_body .box {
  border: none;
}
.TiketNote_header {
  /* margin-bottom: 24px; */
  background-color: var(--headerBG);
  color: var(--headerColor);
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: center;
  cursor: move;
}
.TiketNote_header h3 {
  color: var(--headerColor);
  padding-left: 10px;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
}
.TiketNote_header h5 {
  color: var(--headerColor);
  padding-right: 27px;
  font-weight: 300;
  font-size: 15px;
  line-height: 15px;
  cursor: copy;
}
.box {
  border: 1px solid aliceblue;
  padding: 5px;
  position: relative;
  /* margin-bottom:25px; */
  /* background-color: darkcyan; */
  background: var(--BG1);
  color: var(--Color1);
}

.box .boxtitle {
  /* position: absolute;
  top: -20px;
  left: 7px; */
  /* background: var(--headerBG1); */
  color: var(--headerColor1);
  /* padding: 5px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: underline;
  font-weight: 500;
  font-size: 15px;
}
.boxtitle h3 {
  color: var(--headerColor1);
  font-size: 15px;
  font-weight: 500;
}
.boxtitle_edit {
  display: flex;
}
.boxtitle_edit span {
  /* color: coral; */
  text-decoration: underline;
}
.TiketNote_footer {
  background: var(--BG1);
  color: var(--Color1);
  padding: 3px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer_right {
  display: flex;
  /* flex-direction: column; */
  align-items: left;
  flex: 2;
}

.rowhangup{
  display: flex;
  justify-content: space-evenly;
}

.callto{
  color: #1890ff;
}

.icon1{
  color: white;
  background-color: #1890ff;
  padding: 2px;
  margin-right: 2px;
}

.icon1:hover {
  
  padding: 5px;
  cursor: pointer;
}

.icon{
  background-color: inherit;
  color: #1890ff;
  border-radius: 50%;
  
  
  padding: 2px;
  margin-right: 2px;
}

.icon:hover {
  color: white;
  background-color: #1890ff;
  padding: 5px;
  cursor: pointer;
}

.iconCallOut{
  position: relative;
  margin: 2px;
}


.icon2 {
  /* color: coral; */
  position: absolute;
  /*! font-size: 50px; */
}
.icon22 {
  /*! color: blue; */
  top: -2px;
  left: -2px;
  font-size: 11px;
}
.icon21 {
  top: 3px;
}
.connected {
  color: var(--connected);
}
.notconnected {
  color: var(--notconnected);
}

.callin.connected {
  color: var(--in_connected);
}
.callout.connected {
  color: var(--out_connected);
}
.duration {
  /* background: #09c2fa;
  position: absolute; */
  padding: 5px;
  text-decoration: underline;
  color: tomato;
}

.ant-message-notice.messageRight {
  right: 329px;
  position: fixed;
}