@import "antd/dist/antd.css";
:root {
  --connected:#1e90ffbd;
  --in_connected:blue;
  --out_connected:green;
  --notconnected1:gray;
  --notconnected:red;
  --blue: #1e90ff;
  --blue1: #1e90ffbd;
  --white: #ffffff;

  --headerColor: #ffffff;
  --headerBG: #1e90ff;
  --bodyColor: #ffffff;
  --bodyBG: #1e90ff;
  --footerColor: #ffffff;
  --footerBG: #1e90ff;

  --headerColor1: coral;
  --headerBG1: #1e90ff;

  --Color1: rgb(29, 24, 14);
  --BG1: white;

  --Color2: #ffffff;
  --BG2: #1e90ff;

  --Color3: #ffffff;
  --BG3: #1e90ff;
}

.titleBold{
  /* font-weight: 700; */
  color: var(--blue1);
  font-size: 1rem;
  margin-bottom: 3px;
}
.gap1{
  gap: 50px;
}
.gap10{
  gap: 10px;
}
.minWidth1 {
  min-width: 100px;
}
.minWidth15 {
  min-width: 150px;
}
.minWidth2 {
  min-width: 200px;
}
.mycontainer {
  width: 100%;
}
.rowFlex {
  display: flex;
}
.colFlex{
  display: flex;
  flex-direction: column;
}
/* Header */
.ant-layout-header.myHeader {
  /*! border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  padding: 0px;
  padding-left: 2rem;
  padding-right: 2rem;
}
.ant-layout-header .myHeaderRight {
  margin-top: auto;
  margin-bottom: auto;
}
.ant-layout-header .myHeaderLeft {
  margin-top: auto;
  margin-bottom: auto;
}
.myHeaderRight{
  position: relative;
  width: 180px;
}
/* .myHeaderRight:hover .userMenu{
  display: block;
} */
.userMenu{
  /* display: none; */
  line-height: 32px;
  color:white;
  background-color:rgba(0, 12, 23, 1);
  border-radius: 10px;
  /* overflow: hidden; */

  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  /* width: 200px; */
  /* height: 150px; */
  
  /* border: 1px solid red; */
  z-index: 20000;
  list-style-type: none;
/* text-align: center; */
padding: 0px;
}
.userMenu_item:first-child:before{
  content: "";
  position: absolute;
  top:0px;
  left:1rem;
  /* border-bottom: 10px solid rgba(0, 12, 23, 1); */
  border-bottom: 10px solid rgb(114, 171, 223);
  border-left:10px solid transparent;
  border-right: 10px solid transparent;
  transform: translateY(-100%);
}
.userMenu_item:first-child:hover::before{
  border-bottom: 10px solid var(--headerBG);
  
}
.userMenu_item{
  padding: 10px;
  transition: background-color 0.2s linear;
}
.userMenu_item:hover {
  background-color: var(--headerBG);
   /* rgb(23, 117, 206); */
  /* rgb(0, 21, 41); */
  color: wheat;
  cursor: pointer;
}

/* .userMenu div:first-of-type {
  height: 70px;
  background-color: #1e90ff;
} */
/* .userMenu div:nth-of-type(2){
  display: block;
  line-height: 25px;
  /* background-color: #f9bebe; */
  /* border: 1px solid gray; */
  /* padding:0px;
  margin:0px;
  width: auto;
  text-align: center; */
  /* height: auto; */

/* } */ 
/* .userMenu div:nth-of-type(2) span{
  display: block;
  border-bottom: 1px solid blue;
} */
/* QUickCall */
.Phone {
  /* border: 1px solid salmon; */
  display: flex;
  padding: 1rem 0;
}

.Phone .PhoneQuickCall {
  margin-left: auto;
  display: flex;
  align-items: center;
}
#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.login-form-button {
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hideItem {
  display: none;
}
.stitle {
  font-size: 11pt;
  /* font-style: italic; */
  font-weight: lighter;
  /* display: inline-block; */
  padding-right: 10px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.svalue {
  font-size: 11pt;
  /* font-style: italic; */
  display: inline;
}
.tdAction .ant-btn-link {
  text-align: left;
}

.addButton1 {
  margin-left: auto;
  position: absolute;
  width: 150px;
  right: 4rem;
  font-weight: normal;
  /* bottom: 19.5rem; */
}
.row .addButton2 {
  margin-left: auto;
  position: absolute;
  width: 300px;
  right: 4rem;
  /* bottom: 2rem; */
  display: inherit;
}
.table1.center th {
  text-align: center;
}
.table1.center td {
  text-align: center;
}
body {
  background: #001529;
}

.userRegistered{
  background-color: #87d068;
  margin-right: 7px;
}
.userUnRegistered{
  background-color: gainsboro;
  margin-right: 7px;
}
.userPause{
  background-color: #f9bebe;
  margin-right: 7px;
}
.userInUse{
  background-color: green;
  margin-right: 7px;
}
.userBusy{
  background-color: green;
  margin-right: 7px;
}
.userRinging{
  background-color: yellow;
  margin-right: 7px;
}

.CopyColor{
  color: darkturquoise;
}

.flexrow{
  display: flex;
  flex-wrap: wrap;
  /* justify-content:space-between; */
  width:100%;
  margin: auto;
}
.agentStatusItem{
  width: 25%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.subTitle{
  font-size: 10px;
  color: gray;
}


.left {
  display: flex;
  flex-direction: row;
}
.right {
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  /* padding: 0.5rem; */
}
.row.searchbox {
  /*! border-bottom: 1px solid blue; */
  padding-bottom: 0.5rem;
  border-bottom-style: double;
  border-bottom-color: coral;
  /* background: antiquewhite; */
  align-content: center;
  /*! text-align: center; */
  align-items: center;
  position: relative;
  justify-content: space-between;
}
.doubleline {
  border-bottom-style: double;
  border-bottom-color: coral;
  /* margin-top: -18px; */
  /*! z-index: 1; */
  position: relative;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}



.numberAgentStatus {
  display: block;
  width: 100%;
  padding-bottom: 25px;
}
.numberAgentStatus ul {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  padding: 20px;
  color: gray;
  font-weight: bold;
}

.numberAgentStatus ul li {
  text-decoration: none;
  flex: 1;
  list-style: none;
}

.numberAgentStatus ul li span{
  padding: 5px;
}
.showHideKeypad {
  position: absolute;
  right: 7px;
  top: 7px;
}

.linkGM {
  color: white !important;
}

.linkGM:hover {
  color: #20e8d5 !important;
}


.sumLabel {
  padding: 5px;
}
.sumValue {
  padding: 5px;
}


.dashBoard_flex-container {
  display: flex;
  width: 100%;
  /* border: 1px solid aqua; */
  flex-direction: row;
  justify-content: space-around;
  padding: 2px;
}
.dashBoard_flex-item {
  width: 100%;
  border: 1px solid pink;
  margin: 5px;
  padding: 5px;
  display: flex;
}
.boxChild-Lable {
  font-size: 18px;
  font-weight: bolder;
  text-align: center;
}
.boxChild-Value {
  font-size: 30px;
  color: #f257c4;
  font-weight: bolder;
  text-align: center;
}


.gachduoi {
  text-decoration: underline;
}

.DashboardDisplay {
  width: 100%;
  /*! border: 1px solid aqua; */
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: 10px 10px 5px lightblue;
  padding-bottom: 10px;
}


.mt20 {
  margin-top: 20px;
}


.mt25 {
  margin-top: 25px;
}



.itemLeft {
  flex: 1;
}
.itemRight {
  flex: 2;
}


.Up{
  color:blue;
}
.Ringing{
  color:red;
}

.color-red{
  color: red;
}
.color-blue{
  color: blue;
}
.containerRow{
  display: flex;
}
.containerColumn{
  display: flex;
  flex-direction: column;
  gap:0.5rem;
}
.flexCenter{
  justify-content: center;
  align-items: center;
}
.incomingCallBtnsContainer.disable {
  color: gainsboro;
  cursor: auto;
}
.incomingCallBtnsContainer{
  display: flex;
  width: 100%;
  justify-content: center;
  gap:.2rem;
}
.incomingCallBtnsContainer_item{
  background-color: antiquewhite;
  padding:.7rem;
  border-radius: .5rem;
  cursor: pointer;
}
.incomingCallBtnsContainer_item:hover{
  background-color: rgb(236, 220, 200);
}
.menuTransferContaier{
  margin-top: 1rem;
  }

  .calltoForm{
    margin-bottom: 1rem;
  }
  .calltoForm  label {
    padding-right: 0.5rem;
  }
  .calltoForm input{
    flex:1;
    background-color: rgb(233, 232, 232);
  font-size: 1.25rem;
  width: 100%;
  padding:.25rem;
  padding-right:.5rem;
  padding-left:.5rem;
  
  border-radius: 4px;
  }
  .calltoForm input:focus{
    background-color:  white;
  outline: none;
  border: none;
  }
  .callBtn{
    width: 2rem;
    font-size: 25px;
    padding:0.5rem;
    cursor: pointer;
  }
  .callBtn:hover{
    background-color: #f9f8f8
  }
  .callforTransferMenu{
    width: 100%;
    display: flex;
    /* border: 1px solid blue; */
    gap:.5rem;
    margin-bottom: 1rem;
  }
  .callforTransferMenu div{
    flex:1;
  }
  .menuCalToFormcontainer{
    /* border: 1px solid blue; */
    align-items: center;
    justify-content: center;
    padding-top:1rem;
    /* background-color: antiquewhite; */
  }