.row {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
.col + .col {
  margin-left: 0.5rem;
}
.col label,
button,
input {
  display: inline-block;
  width: 100%;
}

.row h3 {
  font-weight: 700;
  /* background: coral; */
  display: block;
  /* border: 1px solid aqua; */
  width: 100%;
  padding: 0.5em;
  border-bottom-color: coral;
  border-bottom-style: double;
  /* margin-bottom: 1em; */
}
.row .addButton {
  /* margin-left: auto; */
  position: absolute;
  width: 100px;
  right: 4rem;
  /* bottom: 2rem;*/
}
/*seach*/
.search {
  margin-bottom: 1rem;
}
.search .filter {
  display: flex;
  flex-direction: row;
}
.search .filter .filterTitle {
  margin: auto 0;
  margin-right: 0.3rem;
}
.divBtnSearch {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  gap: 0;
}
.divBtnSearch button {
  display: inline-block;
  width: 10rem;
}
/*..........searchResult *................*/
.searchResult {
  /* border: 1px solid salmon;
    width: 100%; */

  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.labelTime {
  width: 3rem;
}

.callLogAudio {
  position: fixed;
  bottom: 0;
}
.table1 {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table1 td,
.table1 th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table1 tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table1 tr:hover {
  background-color: #ddd;
}

.table1 th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
